import { createSlice } from "@reduxjs/toolkit";
import { getAllDispatch, logisticAssignDetails, logisticDashboard } from "../thunk";

const initialState: any = {
  allRiderInfo: [],
  dashboard: [],
  ordersToAssign: [],
  status: "idle",
  error: "",
};

const logisticsSlice = createSlice({
  name: "logistics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllDispatch.pending, (state) => {
        state.status = "loading";
        state.error = "nil";
      })
      .addCase(getAllDispatch.fulfilled, (state, action) => {
        state.allRiderInfo = action.payload;
        state.status = "success";
        state.error = "nil";
      })
      .addCase(getAllDispatch.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error?.message || "Unknown error";
      })
      .addCase(logisticDashboard.pending, (state) => {
        state.status = "loading";
        state.error = "nil";
      })
      .addCase(logisticDashboard.fulfilled, (state, action) => {
        state.dashboard = action.payload;
        state.status = "success";
        state.error = "nil";
      })
      .addCase(logisticDashboard.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error?.message || "Unknown error";
      })
      .addCase(logisticAssignDetails.pending, (state) => {
        state.status = "loading";
        state.error = "nil";
      })
      .addCase(logisticAssignDetails.fulfilled, (state, action) => {
        state.ordersToAssign = action.payload;
        state.status = "success";
        state.error = "nil";
      })
      .addCase(logisticAssignDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error?.message || "Unknown error";
      });
  },
});

export default logisticsSlice.reducer;
