import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Dietitian = () => {
  const navigate = useNavigate();
  const [showClients, setShowClients] = useState(false);
  const [clientDetails, setClientDetails] = useState<any>([]);
  const data = [
    {
      name: "George M",
      clients: [
        {
          name: "Stanley B",
          date: "3/10/2023",
        },
        {
          name: "Stanley B",
          date: "3/10/2023",
        },
        {
          name: "Stanley B",
          date: "3/10/2023",
        },
      ],
      status: "excellent",
    },
    {
      name: "Marcus G",
      clients: [
        {
          name: "Bola R",
          date: "3/10/2023",
        },
        {
          name: "Bola R",
          date: "3/10/2023",
        },
        {
          name: "Bola R",
          date: "3/10/2023",
        },
      ],
      status: "fair",
    },
    {
      name: "Gloria A",
      clients: [
        {
          name: "Mary T",
          date: "3/10/2023",
        },
        {
          name: "Mary T",
          date: "3/10/2023",
        },
        {
          name: "Mary T",
          date: "3/10/2023",
        },
      ],
      status: "poor",
    },
  ];

  const handleClientClick = (item: any) => {
    setClientDetails(item);
    setShowClients(true);
  };

  return (
    <div>
      <h1 className="font-bold text-xl my-3">Dietitians List</h1>
      <div className="bg-white p-5 rounded-md">
        <button
          type="button"
          className="text-white bg-secondary p-2 rounded-md mb-4 float-right"
          onClick={()=> navigate('/dashboard/dietitians/dietitian-requests')}
        >
          Dietitian Requests
        </button>

        <table className="md:w-full w-[700px] md:text-base text-sm text-left">
          <thead>
            <tr className="bg-gray-50 h-12">
              <th className="pl-2">SN</th>
              <th className="pl-2">Dietitian Name</th>
              <th className="pl-2">Clients</th>
              <th className="pl-2">Performance Status</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((item: any, index: any) => (
                <tr key={index} className="border-b-2">
                  <td className="p-3">{index + 1}</td>
                  <td className="p-3">{item.name}</td>
                  <td className="p-3">
                    <button
                      type="button"
                      onClick={() => handleClientClick(item)}
                      className="bg-secondary text-white text-xs p-2 rounded-md"
                    >
                      View
                    </button>
                  </td>
                  <td className="p-3">
                    <span
                      className={`p-2 md:text-sm text-xs rounded-md text-white ${
                        item.status === "excellent"
                          ? "bg-secondary"
                          : item.status === "fair"
                          ? "bg-yellow-300"
                          : "bg-yellow-600"
                      }`}
                    >
                      {item.status}
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className="p-3 text-secondary text-center">
                  No dietitian!
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {showClients && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
            <div className="flex justify-between items-center  text-dark p-2 rounded-t-md mb-3">
              <h2 className="text-lg font-semibold">
                Clients of {clientDetails.name}
              </h2>
              <button
                onClick={() => setShowClients(false)}
                className="text-dark"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <table className="w-full">
              <thead>
                <tr className="bg-gray-50 h-12 text-left">
                  <th className="pl-2">Date</th>
                  <th className="pl-2">Client Name</th>
                </tr>
              </thead>
              <tbody>
                {clientDetails.clients.map((item: any, index: number) => (
                  <tr key={index}>
                    <td className="p-2">{item.date}</td>
                    <td className="p-2">{item.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dietitian;
