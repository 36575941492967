import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { getFees } from "../thunk";

interface FeeState {
  fees: any;
  status: string;
  error: string;
}

const initialState: FeeState = {
  fees: [],
  status: 'idle',
  error: ''
}

const feesSlice = createSlice({
  name: "fees",
  initialState,
  reducers: {  },
  extraReducers: (builder) => {
    builder
      .addCase(getFees.pending, (state) => {
        state.status = 'loading'
        state.error = 'nil'
      })
      .addCase(getFees.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = "success"
        state.fees = action.payload;
        state.error = 'nil'
      })
      .addCase(getFees.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error?.message || "Unknown error"
      })
  }
})

export default feesSlice.reducer
