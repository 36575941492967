import { useEffect, useState } from "react";
import { HiDotsVertical } from "react-icons/hi";
import { IoIosArrowRoundBack } from "react-icons/io";
import { CloseDisbute } from "../../components/modals/CloseDisbute";
import axios from "axios";
import LoadingSpinner from "../../components/elements/LoadingSpinner";

import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import toast from "react-hot-toast";

export const Disbutes = () => {
  const [allDisbutes, setAllDisbutes] = useState<any>([]);
  const [msgDetails, setMsgDetails] = useState<any>({});
  const [text, setText] = useState("");
  const [id, setId] = useState("");
  const [showCloseDisbute, setShowCloseDisbute] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useAppSelector((store: RootState) => store.auth);

  const fetchDisbutes = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/getAllDisputes`);
      setAllDisbutes(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDisbutes();
  }, []);

  const handleClick = async (id: any) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/getSingleDispute/${id}`);
      setMsgDetails(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleReply = async (id: string) => {
    if (text.trim() === "") return;

    const payload = { reason: text };
    try {
      setIsLoading(true);
      await axios.post(`${process.env.REACT_APP_API_URL}/respondToDispute/${id}`, payload, {
        headers: {
          Authorization: token,
        },
      });

      setText("");
      setIsLoading(false);
      fetchDisbutes().then(() => {
        handleClick(id);
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseDispute = (id: string) => {
    setShowCloseDisbute((prev) => !prev);
    setId(id);
  };

  return (
    <main className="w-full md:flex md:mt-0 mt-3 gap-2">
      <section
        className={`bg-white md:w-1/2 px-3 py-2 rounded-md md:block ${
          Object.keys(msgDetails).length === 0 ? "block" : "hidden"
        }`}
      >
        <div className="border-b flex p-2 mb-2 justify-between items-center">
          <h1 className="font-semibold text-lg">Disputes</h1>
        </div>
        <section className="h-[76vh] overflow-y-auto">
          {allDisbutes.length > 0 ? (
            allDisbutes.map((item: any, index: number) => (
              <div
                key={index}
                className={`border p-2 rounded-md flex my-2 gap-2 items-center cursor-pointer ${
                  Object.keys(msgDetails).length > 0 &&
                  msgDetails.disputes[0]._id === item.disputes[0]._id &&
                  " border-secondary200 bg-secondary100 text-secondary"
                }`}
                onClick={() => handleClick(item.disputes[0]?._id)}
              >
                <div className="md:w-14">
                  <img
                    src={item.customerId?.image || "/assets/avatar.svg"}
                    className="w-full"
                    alt={
                      item.customerId
                        ? `${item.customerId?.firstName} ${item.customerId?.lastName}`
                        : "Unknown user"
                    }
                  />
                </div>
                <div className="md:text-sm text-lg md:w-48 w-full text-nowrap">
                  <span>
                    <h3 className="font-semibold p-1 truncate">
                      {item.customerId
                        ? `${item.customerId?.firstName} ${item.customerId?.lastName}`
                        : "Unknown user"}
                    </h3>
                  </span>
                  <span>
                    <p className="truncate p-1">{item.disputes[0]?.log}</p>
                  </span>
                </div>
                {item.disputes.length > 0 ? (
                  <div>
                    <div className="flex items-center justify-between">
                      <div
                        className={`md:text-[9px] w-10 text-center text-sm p-1 rounded-lg text-white ${
                          item.customerId ? "bg-blue-500" : "bg-secondary"
                        }`}
                      >
                        {item.customerId ? "Client" : "Rider"}
                      </div>
                      <div
                        onClick={() => handleCloseDispute(item.disputes[0]._id)}
                      >
                        <HiDotsVertical className="w-5 h-5" />
                      </div>
                    </div>
                    <span className="md:text-xs block pt-2">
                      {new Date(item.disputes[0]?.timestamp).toLocaleTimeString(
                        [],
                        {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                        }
                      )}
                    </span>
                  </div>
                ) : (
                  <span className="bg-red-500 p-2 text-white rounded-md text-xs">
                    CLOSED
                  </span>
                )}
              </div>
            ))
          ) : (
            <div>
              <p className="text-center p-4 text-secondary">No Disputes!</p>
            </div>
          )}
        </section>
      </section>
      <section
        className={`border-8 border-white relative rounded-md overflow-y-auto w-full md:block ${
          Object.keys(msgDetails).length === 0 ? "hidden" : "block"
        }`}
      >
        {msgDetails && Object.keys(msgDetails).length > 0 ? (
          <section className="h-[76vh]">
            <div className="bg-white p-3 flex justify-between items-center">
              <div>
                <div onClick={() => setMsgDetails({})}>
                  <div className="bg-gray-100 rounded-[50%] shadow px-2 py-2 w-fit cursor-pointer md:hidden">
                    <IoIosArrowRoundBack className="w-6 h-6 text-secondary" />
                  </div>
                  <h2 className="font-semibold text-lg w-40 truncate">
                    {msgDetails.disputes[0]?.log}
                  </h2>
                </div>
              </div>
              <div>
                <p className="text-sm">
                  {msgDetails.customerId
                    ? msgDetails.customerId.email
                    : "Unknown email"}
                </p>
                <p className="font-semibold">
                  {msgDetails.customerId
                    ? `${msgDetails.customerId.firstName} ${msgDetails.customerId.lastName}`
                    : "Unknown user"}
                </p>
              </div>
            </div>
            <section>
              <div className="bg-white p-1 w-fit rounded-md text-sm mx-auto my-1">
                {new Date(msgDetails.disputes[0].timestamp).toDateString()}
              </div>
              <div className="p-3">
                <div className="w-2/3 mb-3 float-left">
                  <p className="text-sm">
                    <strong>
                      {msgDetails.customerId
                        ? msgDetails.customerId.firstName
                        : "Unknown user"}
                    </strong>{" "}
                    <span className="bg-white p-2 rounded-md mb-1 block">
                      {msgDetails.disputes[0]?.log}
                      <span className="text-right text-xs block">
                        {new Date(
                          msgDetails.disputes[0].timestamp
                        ).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                          hour12: true,
                        })}
                      </span>
                    </span>
                  </p>
                </div>
                {msgDetails.disputes[0].responseTimestamp ? (
                  <div className="w-2/3 mb-3 float-right">
                    <p className="text-sm">
                      <strong>Admin</strong>
                      <span className="bg-white p-2 rounded-md mb-1 block">
                        {msgDetails.disputes[0].response || "Unknown"}
                        <span className="text-right text-xs block">
                          {new Date(
                            msgDetails.disputes[0].responseTimestamp
                          ).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })}
                        </span>
                      </span>
                    </p>
                  </div>
                ) : null}
              </div>
              <div className="absolute bottom-0 w-full flex gap-1 justify-center items-center">
                <textarea
                  name="message"
                  id="message"
                  className="w-full text-sm m-1 border rounded-md p-2"
                  placeholder="Send a response..."
                  cols={30}
                  rows={4}
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  disabled={msgDetails.disputes[0].responseTimestamp}
                ></textarea>
                <div className="flex justify-end mr-1">
                  <button
                    type="button"
                    className="bg-secondary p-2 text-sm rounded-md text-white"
                    onClick={() => handleReply(msgDetails.disputes[0]._id)}
                    disabled={msgDetails.disputes[0].responseTimestamp}
                  >
                    {isLoading ? <LoadingSpinner /> : "Reply"}
                  </button>
                </div>
              </div>
            </section>
          </section>
        ) : (
          <div className="flex justify-center items-center h-full w-full">
            <p className="text-8xl text-gray-200">DISPUTES</p>
          </div>
        )}
      </section>
      {showCloseDisbute && (
        <CloseDisbute
          setShowCloseDisbute={setShowCloseDisbute}
          id={id}
          fetchDisbutes={fetchDisbutes}
        />
      )}
    </main>
  );
};
