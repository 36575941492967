import { useEffect, useState } from "react";
import { BusyRiderModal } from "../../components/modals/BusyRiderModal";
import axios from "axios";

import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";

export const BusyRiders = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [busyRiders, setBusyRiders] = useState<any>([]);
  const { token } = useAppSelector((store: RootState) => store.auth);

  const fetchBusyRiders = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/logisticBusyRider`,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      setBusyRiders(res.data.orders);
    } catch (error: any) {
      console.error(error.message);
    }
  };
  useEffect(() => {
    fetchBusyRiders();
  }, []);

  return (
    <main>
      <div className="bg-white p-3 mt-3 rounded-md">
        <h1 className="font-bold text-lg">Busy Riders</h1>
      </div>
      <section className="overflow-x-auto">
        <table className="md:w-full w-[700px] my-3">
          <thead>
            <tr className="bg-gray-50 font-bold md:text-base text-sm h-12 text-center">
              <th>SN</th>
              <th>Rider's name</th>
              <th>Rider's number</th>
              <th>Client name</th>
              <th></th>
            </tr>
          </thead>
          <tbody className="text-center md:text-base text-sm">
            {busyRiders.length > 0 ? (
              busyRiders.map((item: any, index: number) => (
                <tr key={index} className="cursor-default">
                  <td>{index + 1}</td>
                  <td className="p-3 border-b">{item.riderFullName}</td>
                  <td className="p-3 border-b">{item.riderPhoneNumber}</td>
                  <td className="p-3 border-b">
                    {item.customerLastName + " " + item.customerFirstName}
                  </td>
                  <td>
                    <button
                      type="button"
                      className="p-2 md:text-sm text-xs hover:bg-green-800 bg-secondary rounded-md text-white"
                      onClick={() => setShowDetails((prev) => !prev)
                      }
                    >
                      View more
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className="p-5 bg-white text-secondary">
                  No Busy Riders!
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
      {showDetails && (
        <BusyRiderModal
          setShowDetails={setShowDetails}
          orderDetails={busyRiders}
        />
      )}
    </main>
  );
};
