const CreditDetailsBox =({isLoading, value, text} : any)=> {
    return (
        <div
        className={`bg-white p-5 rounded-md flex flex-col justify-between border-green-300 shadow-md w-[100%] ${
          isLoading && "animate-pulse "
        }`}
      >
        <h1 className="text-sm font-medium pb-4 text-gray-700 caption-bottom">
          {text}
        </h1>
        <h1 className="font-semibold text-3xl text-right">
          {isLoading ? "0" : value}
        </h1>
      </div>

    )
}

export default CreditDetailsBox