import { useState } from "react";

const Operations =()=> {
    const [staffInView, setStaffInView] = useState("dietitian");
    return (
      <div>
        <h1 className="font-bold text-xl">All Operations</h1>
  
        <section className="flex mt-5 justify-evenly flex-wrap gap-3">
          <div
            className={`bg-white p-5 rounded-md border cursor-pointer border-green-300 shadow-md w-60`}
            onClick={() => setStaffInView("dietitians")}
          >
            <h2 className="text-sm font-medium pb-4 text-gray-700">Dietitians</h2>
          </div>
          <div
            className={`bg-white p-5 rounded-md border cursor-pointer border-green-300 shadow-md w-60`}
            onClick={() => setStaffInView("vendors")}
          >
            <h2 className="text-sm font-medium pb-4 text-gray-700">Vendors</h2>
          </div>
          <div
            className={`bg-white p-5 rounded-md border cursor-pointer border-green-300 shadow-md w-60`}
            onClick={() => setStaffInView("logistics")}
          >
            <h2 className="text-sm font-medium pb-4 text-gray-700">Logistics</h2>
          </div>
          <div
            className={`bg-white p-5 rounded-md border cursor-pointer border-green-300 shadow-md w-60`}
            onClick={() => setStaffInView("restaurants")}
          >
            <h2 className="text-sm font-medium pb-4 text-gray-700">
              Restaurants
            </h2>
          </div>
        </section>
        <section className="bg-white p-2 mt-5 rounded-md capitalize">
          <h2 className="font-semibold">{staffInView} Activities</h2>
        </section>
      </div>
    );  
}

export default Operations;