import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAllVendors } from "../thunk";

interface vendorsState {
    vendors: any;
    status: string;
    error: string;
  }  

const initialState: vendorsState = {
  vendors: [],
  status: "idle",
  error: "",
};

const vendorsSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllVendors.pending, (state) => {
        state.status = "loading";
        state.error = "nil";
      })
      .addCase(getAllVendors.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = "success";
        state.vendors = action.payload;
        state.error = "nil";
      })
      .addCase(getAllVendors.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error?.message || "Unknown error";
      });
  },
});

export default vendorsSlice.reducer;
