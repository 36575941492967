import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/authSlice";
import productReducer from "./products/productSlice"
import orderReducer from "./orders/orderSlice"
import verificationReducer from "./verification/verificationSlice";
import logisticsReducer from "./logistics/logisticsSlice";
import vendorReducer from "./vendors/vendorSlice";
import feeReducer from "./fees/feeSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    products: productReducer,
    orders: orderReducer,
    verification: verificationReducer,
    logistics: logisticsReducer,
    vendors: vendorReducer,
    fees: feeReducer
  }
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
