import React from "react";

const DietitianRequestModal = ({ setShowRequest, selectedRequest }: any) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div
        className="bg-white p-4 rounded-md overflow-auto"
        style={{ width: "500px", height: "600px" }}
      >
        <div className="flex justify-between items-center text-dark p-2 rounded-t-md mb-4">
          <h2 className="text-lg font-semibold">Request Information</h2>
          <button onClick={() => setShowRequest(false)} className="text-dark">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <section>
          <div className=" border-b border-gray-300 mb-8 flex justify-between">
            <h4 className="font-semibold">Full Name:</h4>
            <p>{selectedRequest.name}</p>
          </div>
          <div className="border-b border-gray-300 mb-8 flex justify-between overflow-hidden">
            <h4 className="font-semibold">Phone Number</h4>
            <p>{selectedRequest.phoneNumber}</p>
          </div>
          <div className="border-b border-gray-300 mb-8 flex justify-between overflow-hidden">
            <h4 className="font-semibold">Gender</h4>
            <p>{selectedRequest.gender}</p>
          </div>
          <div className="border-b border-gray-300 mb-8 flex justify-between overflow-hidden">
            <h4 className="font-semibold">Body Weight</h4>
            <p>{selectedRequest.bodyWeight}</p>
          </div>
          <div className="border-b border-gray-300 mb-8 flex justify-between overflow-hidden">
            <h4 className="font-semibold">BMI</h4>
            <p>{selectedRequest.bmi}</p>
          </div>
          <div className="border-b border-gray-300 mb-8 flex justify-between overflow-hidden">
            <h4 className="font-semibold">Allergies:</h4>
            <p>{selectedRequest.allergies}</p>
          </div>
          <div className="border-b border-gray-300 mb-8 flex justify-between overflow-hidden">
            <h4 className="font-semibold">Current Diet:</h4>
            <p>{selectedRequest.currentDiet}</p>
          </div>
          <div className="border-b border-gray-300 mb-8 flex justify-between overflow-hidden">
            <h4 className="font-semibold">Medical Condition:</h4>
            <p>{selectedRequest.currentDiet}</p>
          </div>
          <div className="border-b border-gray-300 mb-8 flex justify-between overflow-hidden">
            <h4 className="font-semibold">Physical activities</h4>
            <p>{selectedRequest.physicalActivities}</p>
          </div>
          <div className="border-b border-gray-300 mb-8 flex justify-between overflow-hidden">
            <h4 className="font-semibold">Other Information</h4>
            <p>{selectedRequest.otherInformation}</p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default DietitianRequestModal;
