import { Navigate } from "react-router-dom";
import { checkTokenExpiration } from "../redux/thunk";
import { useAppSelector } from "../redux/hooks";
import { RootState } from "../redux/store";

const ProtectedRoute = ({ children }: any) => {
    const { token } = useAppSelector((store: RootState) => store.auth);

  if (token) {
    const isTokenValid = checkTokenExpiration();
    
    if (!isTokenValid) {
      return <Navigate to="/" replace />;
    }
  } else {
    return <Navigate to="/" replace />;
  }
  return children;
};

export default ProtectedRoute;
