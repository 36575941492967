import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { getOrders } from "../thunk";

interface OrdersState {
  orders: any;
  status: string;
  error: string;
}

const initialState: OrdersState = {
  orders: [],
  status: 'idle',
  error: ''
}

const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrders.pending, (state) => {
        state.status = 'loading'
        state.error = 'nil'
      })
      .addCase(getOrders.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = "success"
        state.orders = action.payload;
        state.error = 'nil'
      })
      .addCase(getOrders.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error?.message || "Unknown error"
      })

  }
})

export default ordersSlice.reducer
