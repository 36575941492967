import React from "react";

export const RestaurantOrders = ({
  setShowRestaurantOrders,
  orderDetails,
}: any) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
        <div className="flex justify-between items-center  text-dark p-2 rounded-t-md mb-4">
          <h2 className="font-semibold">DELIVERY DETAILS</h2>
          <button
            onClick={() => setShowRestaurantOrders(false)}
            className="text-dark"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <section>
          <table className="w-full text-left mb-5">
            <thead className="border bg-gray-50">
              <tr>
                <th className="p-2 text-sm">Item</th>
                <th className="p-2 text-sm">Quantity</th>
                <th className="p-2 text-sm">Price</th>
              </tr>
            </thead>
            <tbody>
              {orderDetails.items.map((item: any, index: any) => (
                <tr key={index}>
                  <td className="p-2 border text-sm">{item.itemName}</td>
                  <td className="p-2 border text-sm">{item.quantity}</td>
                  <td className="p-2 border text-sm">{item.price}</td>
                </tr>
              ))}
              <tr>
                <td colSpan={2} className="p-2 border-b-2 text-sm font-semibold">Total Price</td>
                <td className="p-2 border-b-2 text-sm font-semibold">
                  {orderDetails.totalPrice}
                </td>
              </tr>
            </tbody>
          </table>
          <div>
            <div className=" border-b border-gray-300 mb-8 flex justify-between w-full">
              <h4 className="font-semibold w-32 text-sm">PickUp Location:</h4>{" "}
              <h5 className="text-sm w-2/3 text-right">
                {orderDetails.restaurant.address}
              </h5>
            </div>
            <div className=" border-b border-gray-300 mb-8 flex justify-between">
              <h4 className="font-semibold w-32 text-sm">Delivery Location:</h4>{" "}
              <h5 className="text-sm">{orderDetails.customer.address}</h5>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
