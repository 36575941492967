export const EMandateModal = ({
  setEMandateModal,
  defaultAmount,
  acctClick,
}: any) => {  
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <section className="bg-white w-1/2">
        <div className="bg-white p-4 rounded-md">
          <div className="flex justify-between items-center text-dark p-2 rounded-t-md mb-4">
            <button
              onClick={() => setEMandateModal(false)}
              className="text-dark"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <section className="overflow-auto">
            <table className="w-full text-left mb-5 text-nowrap">
              <thead className="border bg-gray-50">
                <tr>
                  <th className="p-2 text-xs">Account Name</th>
                  <th className="p-2 text-xs">Account number</th>
                  <th className="p-2 text-xs">Bank Code</th>
                  <th className="p-2 text-xs">Date</th>
                  <th className="p-2 text-xs">Debit Type</th>
                  <th className="p-2 text-xs">Mandate Type</th>
                  <th className="p-2 text-xs">Ready To Debit?</th>
                  <th className="p-2 text-xs">Status</th>
                  <th className="p-2 text-xs">Defaulted Amount</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {acctClick.accounts.length > 0 ? (
                  acctClick.accounts.map((item: any, index: string) => (
                    <tr key={index}>
                      <td className="p-2 border text-sm">
                        {item.account_name}
                      </td>
                      <td className="p-2 border text-sm">
                        {item.account_number}
                      </td>
                      <td className="p-2 border text-sm">
                        {item.institution.bank_code}
                      </td>
                      <td className="p-2 border text-sm">
                        {new Date(item.date).toLocaleDateString()}
                      </td>
                      <td className="p-2 border text-sm">{item.debit_type}</td>
                      <td className="p-2 border text-sm">
                        {item.mandate_type}
                      </td>
                      <td className="p-2 border text-sm">
                        {item.ready_to_debit ? "Yes" : "No"}
                      </td>

                      <td className="p-2 border text-sm">{item.status}</td>
                      <td className="p-2 border text-sm">₦{defaultAmount}</td>
                      <td className="p-2 border text-sm">
                        <button
                          type="button"
                          className="bg-secondary p-2 rounded-md text-sm text-white"
                        >
                          Apply
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={7}
                      className="text-center text-secondary text-sm p-3"
                    >
                      No E-mandate account found!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </section>
        </div>
      </section>
    </div>
  );
};
