import React from "react";

const FailedOrderModal = ({ setShowFailedOrderModal, reason }: any) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
        <div className="flex justify-between items-center  text-dark p-2 rounded-t-md mb-4">
          <h2 className="text-lg font-semibold capitalize">
            Reason for Failed Order
          </h2>
          <button
            onClick={() => setShowFailedOrderModal(false)}
            className="text-dark"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="w-11/12 mx-auto p-3 rounded-md text-center border">
          <h5>{reason}</h5>
        </div>
      </div>
    </div>
  );
};

export default FailedOrderModal;
