import { useEffect, useState } from "react";
import { useAppDispatch } from "../redux/hooks";
import { getAllRepaymentHistory } from "../redux/thunk";

export const Repayment = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  console.log(data);
  

  const dispatch = useAppDispatch();

  useEffect(() => {
    setIsLoading(true);
    dispatch(getAllRepaymentHistory()).then((response: any) => {
      console.log("response", response)
      setData(response.payload);
      setIsLoading(false);
    });
  }, [dispatch]);

  return (
    <main className=" w-full">
      <div className="bg-white rounded-md shadow-md pb-6">
        <div
          className={`w-full overflow-x-auto ${
            isLoading && "animate-pulse h-[50vh]"
          }`}
        >
          <div
            className="flex items-center justify-between p-6"
            style={{ minWidth: "700px" }}
          >
            <div className="flex justify-between w-full">
              <h1 className="text-base font-semibold ">Repayment History</h1>
            </div>
          </div>
          <section className="overflow-x-auto">
            <table className="md:w-[1350px] w-[700px] my-3 text-nowrap">
              <thead className="bg-gray-50 font-bold text-left p-4">
                <tr>
                  <th className="p-2 ">S/N</th>
                  <th className="p-2">Name</th>
                  <th className="p-2 text-nowrap">Phone number</th>
                  <th className="p-2 text-nowrap">Email address</th>
                  <th className="p-2 ">Total Loan</th>
                  <th className="p-2">Paid Loan</th>
                  <th className="p-2">Amount Left</th>
                  <th className="p-2">Pay Date</th>
                </tr>
              </thead>
              <tbody className="px-4">
                {Array.isArray(data) && data.length > 0 ? (
                  data.map((user: any, index: any) => (
                    <tr className="border-b border-gray-300" key={index}>
                      <td className="py-4 px-2 text-secondary p-2">
                        {index + 1}
                      </td>
                      <td className="py-4 px-2 text-nowrap">
                        {user.userId.lastName + " " + user.userId.firstName ||
                          "not found"}
                      </td>
                      <td className="py-4 px-2">
                        {user.userId.phoneNumber || "not found"}
                      </td>
                      <td className="py-4 px-2">
                        {user.userId.email || "not found"}
                      </td>
                      <td className="py-4 px-2">
                        {user.loanRecord[0].totalLoan || "not found"}
                      </td>
                      <td className="py-4 px-2">
                        {user.loanRecord[0].paidLoan || "not found"}
                      </td>
                      <td className="py-4 px-2">
                        {user.loanRecord[0].amountLeft || "not found"}
                      </td>
                      <td className="py-4 px-2">
                        {new Date(
                          user.loanRecord[0].payDate
                        ).toLocaleDateString() || "not found"}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8} className="text-center p-2">No repayment found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </section>
        </div>
      </div>
    </main>
  );
};
