export const BusyRiderModal = ({ setShowDetails, orderDetails }: any) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
        <div className="flex justify-between items-center  text-dark p-2 rounded-t-md mb-4">
          <h2 className="font-semibold">DELIVERY DETAILS</h2>
          <button onClick={() => setShowDetails(false)} className="text-dark">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <section>
          <table className="w-full text-left mb-5">
            <thead className="border bg-gray-50">
              <tr>
                <th className="p-2 text-sm">Item</th>
                <th className="p-2 text-sm">Quantity</th>
                <th className="p-2 text-sm">Price</th>
              </tr>
            </thead>
            <tbody>
              {orderDetails[0].orderItems.map((item: any, index: any) => (
                <tr key={index}>
                  <td className="p-2 border text-sm">{item.name}</td>
                  <td className="p-2 border text-sm">{item.quantity}</td>
                  <td className="p-2 border text-sm">{item.totalPrice}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
        <div className="border-b border-gray-300 mb-8 flex justify-between overflow-hidden">
          <h4 className="font-semibold text-sm">Order date:</h4>{" "}
          <h5 className="text-sm">{orderDetails.orderDate}</h5>
        </div>
        <div className="border-b border-gray-300 mb-8 flex justify-between overflow-hidden">
          <h4 className="font-semibold text-sm">PickUp Location:</h4>{" "}
          <h5 className="text-sm">{orderDetails.pickUpLocation}</h5>
        </div>
        <div className="border-b border-gray-300 mb-8 flex justify-between overflow-hidden">
          <h4 className="font-semibold text-sm">
            Has the item been picked up?
          </h4>{" "}
          <h5 className="text-sm">{orderDetails.pickedUp ? "YES" : "NO"}</h5>
        </div>
        <div className="border-b border-gray-300 mb-8 flex justify-between overflow-hidden">
          <h4 className="font-semibold text-sm">Delivery Address:</h4>{" "}
          <h5 className="text-sm">{orderDetails.deliveryDetails}</h5>
        </div>
        <div>
          <button
            type="button"
            className="p-2 rounded-lg text-sm text-white bg-secondary hover:bg-green-700"
          >
            View rider's location
          </button>
        </div>
      </div>
    </div>
  );
};
