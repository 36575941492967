import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { products } from "../../types/types";
import { getAllProductType, getAllProducts } from "../thunk";

interface ProductsState {
  products: products[];
  categories: any;
  status: string;
  error: string;
}

const initialState: ProductsState = {
  products: [],
  categories: [],
  status: 'idle',
  error: ''
}

const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllProducts.pending, (state) => {
        state.status = 'loading'
        state.error = 'nil'
      })
      .addCase(getAllProducts.fulfilled, (state, action: PayloadAction<products[]>) => {
        state.status = "success"
        state.products = action.payload;
        state.error = 'nil'
      })
      .addCase(getAllProducts.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error?.message || "Unknown error"
      })

      .addCase(getAllProductType.pending, (state) => {
        state.status = 'loading'
        state.error = 'nil'
      })
      .addCase(getAllProductType.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = "success"
        state.categories = action.payload;
        state.error = 'nil'
      })
      .addCase(getAllProductType.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error?.message || "Unknown error"
      })
  }
})

export default productsSlice.reducer
