import { NavbarProps } from "../types/types";
import { HiOutlineMenu } from "react-icons/hi";
import { TfiClose } from "react-icons/tfi";

const Navbar = ({ toggleTabNavigation, tabNavigation }: NavbarProps) => {

  return (
    <div className="flex lg:hidden fixed w-full lg:w-[80%] items-center justify-between bg-white py-4 px-4 lg:px-8">
      <div className="flex lg:hidden items-center justify-between w-full">
        <img className="w-14" src="/assets/logo.png" alt="logo" />
        <div onClick={toggleTabNavigation} className="text-3xl lg:hidden">
          {!tabNavigation ? <HiOutlineMenu /> : <TfiClose />}
        </div>
      </div>
    </div>  
  );
};

export default Navbar;
