import PropTypes from "prop-types";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

const Pagination = ({
  itemsPerPage,
  length,
  handlePagination,
  currentPage,
  prevPage,
  nextPage
}: any) => {
  const paginationNumbers = [];

  const maxDisplayedPages = 5;
  const totalPages = Math.ceil(length / itemsPerPage);

  let startPage = Math.max(1, currentPage - Math.floor(maxDisplayedPages / 2));
  let endPage = Math.min(startPage + maxDisplayedPages - 1, totalPages);

  if (endPage - startPage < maxDisplayedPages - 1) {
    startPage = Math.max(1, endPage - maxDisplayedPages + 1);
  }

  for (let i = startPage; i <= endPage; i++) {
    paginationNumbers.push(i);
  }

  return (
    <div className="flex justify-between ">
      <span className="text-xs opacity-75">
        Showing data {(currentPage - 1) * itemsPerPage + 1} to{" "}
        {Math.min(currentPage * itemsPerPage, length)} of {length} entries
      </span>
      <div className="flex gap-2 items-center">
        <button
          type="button"
          onClick={() => prevPage()}
          className="bg-[#EEEEEE]"
          disabled={currentPage === 1}
        >
          <BiChevronLeft className="w-6 h-6" />
        </button>
        {startPage !== 1 && (
          <>
            <button
              type="button"
              onClick={() => handlePagination(1)}
              className={`px-2 bg-[#EEEEEE]`}
            >
              1
            </button>
            {startPage > 2 && <span>...</span>}
          </>
        )}
        {paginationNumbers.map((pageNumber) => (
          <button
            type="button"
            key={pageNumber}
            onClick={() => handlePagination(pageNumber)}
            className={`px-2 ${
              currentPage === pageNumber
                ? "bg-[#B5572C] text-white"
                : "bg-[#EEEEEE]"
            }`}
          >
            {pageNumber}
          </button>
        ))}
        {endPage !== totalPages && (
          <>
            {endPage < totalPages - 1 && <span>...</span>}
            <button
              type="button"
              onClick={() => handlePagination(totalPages)}
              className={`px-2 bg-[#EEEEEE]`}
            >
              {totalPages}
            </button>
          </>
        )}
        <button
          type="button"
          onClick={() => nextPage()}
          className="bg-[#EEEEEE]"
          disabled={currentPage === totalPages}
        >
          <BiChevronRight className="w-6 h-6" />
        </button>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  itemsPerPage: PropTypes.number,
  length: PropTypes.number,
  currentPage: PropTypes.number,
  handlePagination: PropTypes.func,
  prevPage: PropTypes.func,
  nextPage: PropTypes.func,
};

export default Pagination;
