const OrderHistoryModal = ({ setShowOrderHistory, userOrders }: any) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div
        className="bg-white p-4 rounded-md overflow-auto"
        style={{ width: "700px", height: "600px" }}
      >
        <div className="flex justify-between items-center text-dark p-2 rounded-t-md mb-4">
          <h2 className="text-lg font-semibold">Order History</h2>
          <button
            onClick={() => setShowOrderHistory(false)}
            className="text-dark"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <section>
          <div>
            <table className="w-full">
              <thead className="text-left rounded bg-gray-100">
                <tr>
                  <th className="p-3">SN</th>
                  <th>Date</th>
                  <th>Order Number</th>
                  <th>Status</th>
                  <th>Total price</th>
                </tr>
              </thead>
              <tbody>
                {userOrders.length > 0 ? (
                  userOrders.map((order: any, index: any) => (
                    <tr key={index} className="hover:bg-gray-50 cursor-pointer">
                      <td className="p-3 border-b">{index + 1}</td>
                      <td className="border-b">
                        {new Date(order.orderDate).toLocaleDateString()}
                      </td>
                      <td className="border-b">{order.orderNumber}</td>
                      <td className="border-b">{order.status}</td>
                      <td className="border-b">
                        ₦{Math.round(order.allItemsTotalPrice)}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} className="text-center text-secondary p-5">
                      No orders found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </section>
      </div>
    </div>
  );
};

export default OrderHistoryModal;
