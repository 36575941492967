import { useLocation } from "react-router-dom";

const VendorInfo = () => {
  const location = useLocation();
  const vendor = location.state.item;

  return (
    <main>
      <h2 className="font-bold text-lg uppercase">Vendor Information</h2>
      <section className="md:w-9/12 bg-white py-7 px-10 m-5 rounded-md">
        <div className="flex justify-between items-center mb-3">
          <label className="md:text-base text-sm font-medium" htmlFor="name">
            Full name:
          </label>
          <input
            className="border w-3/5 md:text-base text-sm p-2 rounded-md"
            type="text"
            name="name"
            id="name"
            disabled
            value={vendor.fullName}
          />
        </div>
        <div className="flex justify-between items-center mb-3">
          <label
            className="md:text-base text-sm font-medium"
            htmlFor="phoneNumber"
          >
            Phone Number:
          </label>
          <input
            className="border w-3/5 md:text-base text-sm p-2 rounded-md"
            type="phoneNumber"
            name="phoneNumber"
            id="phoneNumber"
            disabled
            value={vendor.phoneNumber}
          />
        </div>
        <div className="flex justify-between items-center mb-3">
          <label
            className="md:text-base text-sm font-medium"
            htmlFor="address"
          >
            Address:
          </label>
          <input
            className="border w-3/5 md:text-base text-sm p-2 rounded-md"
            type="address"
            name="address"
            id="address"
            disabled
            value={vendor.address}
          />
        </div>
        <div className="flex justify-between items-center mb-3">
          <label
            className="md:text-base text-sm font-medium"
            htmlFor="bankName"
          >
            Bank Name:
          </label>
          <input
            className="border w-3/5 md:text-base text-sm p-2 rounded-md"
            type="text"
            name="bankName"
            id="bankName"
            disabled
            value={vendor.bankDetails.bankName}
          />
        </div>
        <div className="flex justify-between items-center mb-3">
          <label
            className="md:text-base text-sm font-medium"
            htmlFor="accountNo"
          >
            Account Number:
          </label>
          <input
            className="border w-3/5 md:text-base text-sm p-2 rounded-md"
            type="text"
            name="accountNo"
            id="accountNo"
            disabled
            value={vendor.bankDetails.accountNo}
          />
        </div>
        <div className="flex justify-between items-center mb-3">
          <label
            className="md:text-base text-sm font-medium"
            htmlFor="accountName"
          >
            Account Name:
          </label>
          <input
            className="border w-3/5 md:text-base text-sm p-2 rounded-md"
            type="text"
            name="accountName"
            id="accountName"
            disabled
            value={vendor.bankDetails.name}
          />
        </div>
      </section>
    </main>
  );
};

export default VendorInfo;
