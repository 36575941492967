import { useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { IoSearch } from "react-icons/io5";
import { MdWavingHand } from "react-icons/md";
import { RiNotification2Fill } from "react-icons/ri";
import OrderHistoryModal from "../../components/modals/OrderHistoryModal";

const data = [
  {
    name: "Jenifer 0",
    order: [],
    requestNumber: "123456789",
    date: "12-03-2023",
    status: "pending",
  },
  {
    name: "Winnie D",
    order: [],
    requestNumber: "123456789",
    date: "12-03-2023",
    status: "treated",
  },
  {
    name: "Feranmi J",
    order: [],
    requestNumber: "123456789",
    date: "12-03-2023",
    status: "processing",
  },
];

const DashboardLanding = () => {
  const [showOrderHistory, setShowOrderHistory] = useState(false);
  const [userOrders, setUserOrders] = useState<any[]>([]);
  const handleClick = (order: any) => {
    setUserOrders(order);
    setShowOrderHistory((prev) => !prev);
  };

  return (
    <main>
      <section className="flex justify-between mb-1 items-center p-5 bg-white rounded-t-lg">
        <div className="flex gap-3">
          <img
            src="/assets/picture.jpg"
            className="w-14 h-14 rounded-full"
            alt="dietitian name"
          />
          <h1 className="font-bold text-xl flex items-center gap-2">
            Hello Doc <MdWavingHand className="w-6 h-6 text-[#F59040]" />
          </h1>
        </div>
        <div>
          <RiNotification2Fill className="w-8 h-8 text-secondary" />
        </div>
      </section>

      <section className="text-secondary p-5 mb-1 bg-white rounded-b-lg flex justify-around items-center">
        <div>
          <p className="text-sm font-medium">Treated Request</p>
          <p className="font-bold text-4xl">
            <span className="text-sm align-top">=</span>30
          </p>
        </div>
        <div>
          <p className="text-sm font-medium">Processing Request</p>
          <p className="font-bold text-4xl">
            <span className="text-sm align-top">=</span>30
          </p>
        </div>
        <div>
          <p className="text-sm font-medium">Pending Request</p>
          <p className="font-bold text-4xl">
            <span className="text-sm align-top">=</span>30
          </p>
        </div>
        <div>
          <p className="text-sm font-medium">Request Count</p>
          <p className="font-bold text-4xl">
            <span className="text-sm align-top">=</span>90
          </p>
        </div>
      </section>

      <section className="rounded-xl bg-white p-5 pb-10">
        <h2 className="font-bold text-lg">Clients</h2>
        <div className="flex items-center justify-between py-4">
          <p className="text-secondary">Activities</p>
          <div className="flex gap-3 items-center justify-between">
            <div className="relative">
              <IoSearch className="absolute top-2 left-3 w-5 h-5 text-[#7E7E7E]" />
              <input
                type="search"
                name="search"
                className="bg-[#F9FBFF] p-2 indent-7 rounded-md text-sm"
                id="search"
                placeholder="Search"
              />
            </div>
            <div className="bg-[#F9FBFF] p-2 rounded-md text-sm">
              <label htmlFor="sort" className="bg-[#F9FBFF] text-[#7E7E7E]">
                Sort By:
                <select
                  name="sort"
                  id="sort"
                  className="bg-[#F9FBFF] text-black w-[6rem]"
                >
                  <option value="newest">Newest</option>
                </select>
              </label>
            </div>
          </div>
        </div>

        <table className="my-5 w-full">
          <thead className="text-[#B5B7C0] font-medium text-sm text-left border-b">
            <tr>
              <th className="pb-2">Client Name</th>
              <th className="pb-2">Order History</th>
              <th className="pb-2">Request Number</th>
              <th className="pb-2">Date of Request</th>
              <th className="pb-2">Status</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index} className="border-b py-4 font-medium text-sm">
                <td className="py-2">{item.name}</td>
                <td className="py-2">
                  <button
                    type="button"
                    className="flex gap-3 items-center"
                    onClick={() => handleClick(item.order)}
                  >
                    View order <BiChevronDown className="w-5 h-5" />
                  </button>
                </td>
                <td className="py-2">{item.requestNumber}</td>
                <td className="py-2">{item.date}</td>
                <td className="py-2">
                  <p
                    className={`border p-2 px-5 text-center w-fit ${
                      item.status === "treated"
                        ? "border-[#DF0404] text-[#0f0e0e] bg-[#FFC5C5]"
                        : item.status === "pending"
                        ? "border-secondary text-secondary bg-[#16C09861]"
                        : "border-[#EA8E50] text-[#EA8E50] bg-[#F2BB78]"
                    }`}
                  >
                    {item.status}
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
      {showOrderHistory && (
        <OrderHistoryModal
          setShowOrderHistory={setShowOrderHistory}
          userOrders={userOrders}
        />
      )}
    </main>
  );
};

export default DashboardLanding;
