import axios from "axios";
import { useState } from "react";
import toast from "react-hot-toast";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";

export const CardDebit = ({
  setCardDebitModal,
  defaultAmount,
  allCards,
}: any) => {
  const [seeBalance, setSeeBalance] = useState(false);
  const { token } = useAppSelector((store: RootState) => store.auth);

  const handleDebit = async (
    authCode: string,
    email: string,
    userId: string
  ) => {
    const payload = {
      authorization_code: authCode,
      billingEmail: email,
      amountPay: defaultAmount,
      userId: userId,
    };

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/createPaystackChargeCardTokenization`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (res.status === 200) {
        toast.success(res.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <section className="bg-white w-1/2">
        <div className="bg-white p-4 rounded-md">
          <div className="flex justify-between items-center text-dark p-2 rounded-t-md mb-4">
            <h2 className="font-semibold">REGISTERED CARDS</h2>
            <button
              onClick={() => setCardDebitModal(false)}
              className="text-dark"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <section className="overflow-auto">
            <table className="w-full text-left mb-5 text-nowrap">
              <thead className="border bg-gray-50">
                <tr>
                  <th className="p-2 text-xs">Account Name</th>
                  <th className="p-2 text-xs">Customer Email</th>
                  <th className="p-2 text-xs">Card Number</th>
                  <th className="p-2 text-xs">Balance</th>
                  <th className="p-2 text-xs">Expiry Date</th>
                  <th className="p-2 text-xs"></th>
                </tr>
              </thead>
              <tbody>
                {allCards.cards.map((item: any, index: number) => (
                  <tr key={index}>
                    <td className="p-2 border text-xs capitalize">
                      {item.cardholderName}
                    </td>
                    <td className="p-2 border text-xs capitalize">
                      {item.customerEmail}
                    </td>
                    <td className="p-2 border text-xs capitalize">
                      {item.cardNumber}
                    </td>
                    <td
                      className={`p-2 border text-sm flex gap-2 ${
                        !seeBalance && "py-5"
                      }`}
                    >
                      <input
                        type="checkbox"
                        name="balance"
                        id="balance"
                        onChange={() => setSeeBalance((prev) => !prev)}
                      />
                      <span
                        className={`text-sm border p-2 ${
                          seeBalance ? "block" : "hidden"
                        }`}
                      >
                        {item.amount}
                      </span>
                    </td>
                    <td className="p-2 border text-xs capitalize">
                      {new Date(item.expiryDate).toLocaleDateString()}
                    </td>
                    <td className="p-2 border text-xs capitalize">
                      <button
                        type="button"
                        className="bg-secondary p-2 rounded-md text-sm text-white"
                        onClick={() =>
                          handleDebit(
                            item.authorization_code,
                            item.customerEmail,
                            item._id
                          )
                        }
                      >
                        Debit
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
        </div>
      </section>
    </div>
  );
};
