export const EMandateAccts = ({ setShowEmandateModal, acctNameClick }: any) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <section className="bg-white w-1/2">
        <div className="bg-white p-4 rounded-md">
          <div className="flex justify-between items-center text-dark p-2 rounded-t-md mb-4">
            <h2 className="font-semibold">ALL EMANDATE ACCOUNTS DETAILS</h2>
            <button
              onClick={() => setShowEmandateModal(false)}
              className="text-dark"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <section className="overflow-auto">
            <table className="w-full text-left mb-5 text-nowrap">
              <thead className="border bg-gray-50">
                <tr>
                  <th className="p-2 text-xs">Account Name</th>
                  <th className="p-2 text-xs">Account number</th>
                  <th className="p-2 text-xs">Bank Code</th>
                  <th className="p-2 text-xs">Amount</th>
                  <th className="p-2 text-xs">Total Debit Amount</th>
                  <th className="p-2 text-xs">Date</th>
                  <th className="p-2 text-xs">Start Date</th>
                  <th className="p-2 text-xs">End Date</th>
                  <th className="p-2 text-xs">Approved?</th>
                  <th className="p-2 text-xs">Debit Type</th>
                  <th className="p-2 text-xs">Mandate Type</th>
                  <th className="p-2 text-xs">Nibss Code</th>
                  <th className="p-2 text-xs">Ready To Debit?</th>
                  <th className="p-2 text-xs">Reference</th>
                  <th className="p-2 text-xs">Status</th>
                </tr>
              </thead>
              <tbody>
                {acctNameClick.accounts.map((item: any, index: number) => (
                  <tr key={index}>
                    <td className="p-2 border text-xs capitalize">
                      {item.account_name}
                    </td>
                    <td className="p-2 border text-xs capitalize">
                      {item.account_number}
                    </td>
                    <td className="p-2 border text-xs capitalize">
                      {item.institution.bank_code}
                    </td>
                    <td className="p-2 border text-xs capitalize">
                      ₦{item.amount}
                    </td>
                    <td className="p-2 border text-xs capitalize">
                      ₦{item.total_debit_amount}
                    </td>
                    <td className="p-2 border text-xs capitalize">
                      {new Date(item.date).toDateString()}
                    </td>
                    <td className="p-2 border text-xs capitalize">
                      {new Date(item.start_date).toDateString()}
                    </td>
                    <td className="p-2 border text-xs capitalize">
                      {new Date(item.end_date).toDateString()}
                    </td>
                    <td className="p-2 border text-xs capitalize">
                      {item.approved ? "Yes" : "No"}
                    </td>
                    <td className="p-2 border text-xs capitalize">
                      {item.debit_type}
                    </td>
                    <td className="p-2 border text-xs capitalize">
                      {item.mandate_type}
                    </td>
                    <td className="p-2 border text-xs capitalize">
                      {item.nibss_code}
                    </td>
                    <td className="p-2 border text-xs capitalize">
                      {item.ready_to_debit ? "Yes" : "No"}
                    </td>
                    <td className="p-2 border text-xs capitalize">
                      {item.reference}
                    </td>
                    <td className="p-2 border text-xs capitalize">
                      {item.status}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <p>.</p>
          </section>
        </div>
      </section>
    </div>
  );
};
