import { useEffect, useState } from "react";
import BikeDetails from "../../components/modals/BikeDetails";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/elements/LoadingSpinner";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getAllDispatch, logisticDashboard } from "../../redux/thunk";

export const Logistics = () => {
  const [showBikeDetails, setShowBikeDetails] = useState(false);
  const [riderDetails, setRiderDetails] = useState({});
  const navigate = useNavigate();
  const { allRiderInfo, dashboard, status } = useAppSelector(
    (store) => store.logistics
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllDispatch());
    dispatch(logisticDashboard());
  }, [dispatch]);

  const handleViewMore = (plateNo: string, bikeModel: string) => {
    setShowBikeDetails((prev) => !prev);
    setRiderDetails({ plateNo, bikeModel });
  };

  return (
    <main>
      <h2 className="font-bold text-lg">Logistics</h2>
      <section className="flex mt-5 justify-evenly flex-wrap gap-3">
        <div
          className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-60 ${
            status === "pending" && "animate-pulse "
          }`}
        >
          <h2 className="text-sm font-medium pb-4 text-gray-700 caption-bottom">
            Total Riders
          </h2>
          <p className="font-semibold text-3xl">
            {status === "pending" ? "0" : dashboard.totalDispatchers}
          </p>
        </div>
        <div
          className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-60 ${
            status === "pending" && "animate-pulse "
          }`}
        >
          <h2 className="text-sm font-medium pb-4 text-gray-700 caption-bottom">
            Riders on move
          </h2>
          <p className="font-semibold text-3xl">
            {status === "pending" ? "0" : dashboard.dispatchersOnMove}
          </p>
        </div>
        <div
          className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-60 ${
            status === "pending" && "animate-pulse "
          }`}
        >
          <h2 className="text-sm font-medium pb-4 text-gray-700 caption-bottom">
            Available riders
          </h2>
          <p className="font-semibold text-3xl">
            {status === "pending" ? "0" : dashboard.availableDispatchers}
          </p>
        </div>
      </section>
      <section className="my-5 overflow-x-scroll">
        <section className="md:w-full w-[700px]">
          <div className="flex justify-between items-center w-full bg-white rounded-lg p-3 mb-2 gap-2">
            <h1 className="font-semibold ">Delivery Riders</h1>
            <button
              className="bg-secondary text-white p-2 rounded-lg md:text-sm text-xs hover:bg-green-800"
              type="button"
              onClick={() => navigate("busy-riders")}
            >
              Busy Riders
            </button>
            <button
              className="bg-secondary text-white p-2 rounded-lg md:text-sm text-xs hover:bg-green-800"
              type="button"
              onClick={() => navigate("assign-delivery")}
            >
              Assign Delivery
            </button>
            <button
              className="bg-secondary text-white p-2 rounded-lg md:text-sm text-xs hover:bg-green-800"
              type="button"
              onClick={() => navigate("all-deliveries")}
            >
              All deliveries
            </button>

            <button
              className="bg-secondary text-white p-2 rounded-lg md:text-sm text-xs hover:bg-green-800"
              type="button"
              onClick={() => navigate("disbutes")}
            >
              Disputes
            </button>
            <label className="md:text-sm text-xs" htmlFor="filter">
              Sort:
              <select name="filter" id="filter" className="border p-2 ml-4">
                <option value="all">All</option>
                <option value="online">Online</option>
              </select>
            </label>
          </div>
          {status === "pending" ? (
            <LoadingSpinner />
          ) : (
            <table className="w-full text-left">
              <thead>
                <tr className="bg-gray-50 font-bold h-12 md:text-base text-sm">
                  <th>SN</th>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {allRiderInfo.length > 0 ? (
                  allRiderInfo.map((item: any, index: any) => (
                    <tr key={index} className="border-b-2 hover:bg-gray-200">
                      <td className="md:text-base text-sm">{index + 1}</td>
                      <td
                        className="cursor-pointer"
                        onClick={() =>
                          navigate("rider-activity", {
                            state: {
                              id: item._id,
                              email: item.email,
                              firstName: item.firstName,
                              lastName: item.lastName,
                              status: item.status,
                              phoneNo: item.phoneNumber,
                              image: item.image,
                            },
                          })
                        }
                      >
                        <div className="w-12 h-12 mx-auto my-1">
                          <img
                            src={item.image}
                            className="w-full h-full"
                            alt={item.firstName}
                          />
                        </div>
                      </td>
                      <td
                        className="cursor-pointer md:text-base text-sm"
                        onClick={() =>
                          navigate("rider-activity", {
                            state: {
                              id: item._id,
                              email: item.email,
                              firstName: item.firstName,
                              lastName: item.lastName,
                              status: item.status,
                              phoneNo: item.phoneNumber,
                              image: item.image,
                            },
                          })
                        }
                      >
                        {item.lastName + " " + item.firstName}
                      </td>
                      <td
                        className="cursor-pointer md:text-base text-sm"
                        onClick={() =>
                          navigate("rider-activity", {
                            state: {
                              id: item._id,
                              email: item.email,
                              firstName: item.firstName,
                              lastName: item.lastName,
                              status: item.status,
                              phoneNo: item.phoneNumber,
                              image: item.image,
                            },
                          })
                        }
                      >
                        {item.email}
                      </td>
                      <td>
                        <button
                          type="button"
                          onClick={() =>
                            handleViewMore(
                              item.vehiclePlate,
                              item.bikeModel_Color
                            )
                          }
                          className="p-2 md:text-sm text-xs bg-secondary rounded-md text-white"
                        >
                          Bike Info
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} className="p-5 bg-white text-center text-secondary">
                      No Riders!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </section>
      </section>
      {showBikeDetails && (
        <BikeDetails
          setShowBikeDetails={setShowBikeDetails}
          riderDetails={riderDetails}
        />
      )}
    </main>
  );
};
