import { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';

const SocketContext = createContext(null);

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }: any) => {
    const [socket, setSocket] = useState<null | any>(null);

    useEffect(() => {
        const newSocket = io('https://foodbank-backend-x3k8.onrender.com'); 
        setSocket(newSocket);
        
        return () => {
            newSocket.disconnect();
        };
    }, []);

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
};

