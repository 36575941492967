import { useState } from "react";
import { IoMdShareAlt } from "react-icons/io";
import { MdFileDownload } from "react-icons/md";
import CreditDetailsBox from "../../components/CreditDetailsBox";
import { useLocation } from "react-router-dom";

const CreditDetails = () => {
  const [isLoading] = useState(false);
  const location = useLocation();
  const bvnData = location?.state?.response;

  const loanHistoryItems = bvnData.score?.loanHistory;
  const loanPerformanceItems = bvnData.score?.loanPerformance;
  const loansPerPage = 2; // Number of items to display per page
  const oneLoanPerPage = 1;

  const [pagination, setPagination] = useState({
    historyPage: 0,
    performancePage: 0,
    creditEnquiry: 0,
    // creditorHistory: 0,
  });

  const getPaginatedData = (items: any, page: number) => {
    const start = page * loansPerPage;
    return items.slice(start, start + loansPerPage);
  };

  const displayedLoanHistory = getPaginatedData(
    loanHistoryItems,
    pagination.historyPage
  );
  const displayedLoanPerformance = getPaginatedData(
    loanPerformanceItems,
    pagination.performancePage
  );
  const displayedCreditEnquiry = getPaginatedData(
    loanHistoryItems,
    pagination.creditEnquiry
  );
  // const displayedCreditorHistory = getPaginatedData(
  //   loanPerformanceItems,
  //   pagination.creditorHistory
  // );

  const nextPage = (
    type:
      | "historyPage"
      | "performancePage"
      | "creditEnquiry"
      | "creditorHistory",
    items: any[],
    itemPerPage: number
  ) => {
    setPagination((prev: any) => {
      const nextPage = prev[type] + 1;
      if (nextPage * itemPerPage < items.length) {
        return { ...prev, [type]: nextPage };
      }
      return prev;
    });
  };

  const prevPage = (
    type:
      | "historyPage"
      | "performancePage"
      | "creditEnquiry"
      | "creditorHistory"
  ) => {
    setPagination((prev: any) => {
      const prevPage = prev[type] - 1;
      if (prevPage >= 0) {
        return { ...prev, [type]: prevPage };
      }
      return prev;
    });
  };

  return (
    <div className="bg-white p-5 rounded-md">
      <h1 className="text-xl mb-3 font-bold">Customer Credit Details</h1>
      <section className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <img
            src="/assets/avatar.svg"
            alt={bvnData.name}
            className="w-10 p-1 rounded-full border"
          />
          <h2 className="font-semibold text-lg">
            {bvnData.name ? bvnData.name : "unknown"}
          </h2>
        </div>
        <div className="flex items-center gap-3">
          <button
            type="button"
            className="flex items-center border p-2 bg-gray-50"
          >
            <IoMdShareAlt className="w-6 h-6" />
            Share
          </button>
          <button
            type="button"
            className="flex items-center border p-2 bg-gray-50"
          >
            <MdFileDownload className="w-6 h-6" />
            Download
          </button>
        </div>
      </section>
      <section className="my-2">
        <p className="flex w-1/3 p-1 justify-between">
          <span>Date of birth</span>
          <span className="w-1/2">
            {bvnData.dateOfBirth ? bvnData.dateOfBirth : "unknown"}
          </span>
        </p>
        <p className="flex w-1/3 p-1 justify-between">
          <span>Gender</span>
          <span className="w-1/2">
            {bvnData.gender ? bvnData.gender : "unknown"}
          </span>
        </p>
        <p className="flex w-1/3 p-1 justify-between">
          <span>Phone Number</span>
          <span className="w-1/2">
            {bvnData.phone ? bvnData.phone : "unknown"}
          </span>
        </p>
        <p className="flex w-1/3 p-1 justify-between">
          <span>BVN</span>
          <span className="w-1/2">{bvnData.bvn ? bvnData.bvn : "unknown"}</span>
        </p>
        <p className="flex w-4/5 p-1 justify-between">
          <span>Address</span>
          <span className="w-4/5 pl-1 ">
            {bvnData.address ? bvnData.address : "unknown"}
          </span>
        </p>
      </section>

      <section className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-5 mb-8 mt-[1.5rem]">
        <CreditDetailsBox
          isLoading={isLoading}
          text={"Total No. of loans"}
          value={bvnData.score?.totalNoOfLoans}
        />
        <CreditDetailsBox
          isLoading={isLoading}
          text={"Total No. of institutions"}
          value={bvnData.score?.totalNoOfInstitutions}
        />
        <CreditDetailsBox
          isLoading={isLoading}
          text={"Total No. of active loans"}
          value={bvnData.score?.totalNoOfActiveLoans}
        />
        <CreditDetailsBox
          isLoading={isLoading}
          text={"Total No. of closed loans"}
          value={bvnData.score?.totalNoOfClosedLoans}
        />
        <CreditDetailsBox
          isLoading={isLoading}
          text={"Total No. of performing loans"}
          value={bvnData.score?.totalNoOfPerformingLoans}
        />
        <CreditDetailsBox
          isLoading={isLoading}
          text={"Total No. of delinquent facilities"}
          value={bvnData.score?.totalNoOfDelinquentFacilities}
        />
        <CreditDetailsBox
          isLoading={isLoading}
          text={"Highest loan amount"}
          value={bvnData.score?.highestLoanAmount}
        />
        <CreditDetailsBox
          isLoading={isLoading}
          text={"Total monthly installment"}
          value={bvnData.score?.totalMonthlyInstallment}
        />
        <CreditDetailsBox
          isLoading={isLoading}
          text={"Total borrowed"}
          value={bvnData.score?.totalBorrowed}
        />
        <CreditDetailsBox
          isLoading={isLoading}
          text={"Total outstanding"}
          value={bvnData.score?.totalOutstanding}
        />
        <CreditDetailsBox
          isLoading={isLoading}
          text={"Total overdue"}
          value={bvnData.score?.totalOverdue}
        />
        <CreditDetailsBox
          isLoading={isLoading}
          text={"Max No. of days"}
          value={bvnData.score?.maxNoOfDays}
        />
      </section>

      <section className="flex gap-4 justify-between">
        <section className="border rounded-md w-1/3">
          <div className="bg-secondary rounded-t-md p-4">
            <h3 className="text-white font-semibold">Creditors history</h3>
          </div>
          <div className="p-4 border-b">
            <p>Details</p>
          </div>
          <div className="p-4 border-b text-center">
            <p>Check back</p>
          </div>
          <div className="flex justify-between items-center p-2">
            <button type="button" className="bg-secondary p-2 text-white">
              ← Prev
            </button>
            <button type="button" className="bg-secondary p-2 text-white">
              Next →
            </button>
          </div>
        </section>
        <section className="border rounded-md w-2/3">
          <div className="bg-secondary rounded-t-md p-4">
            <h3 className="text-white font-semibold">
              Credit enquiries the last 12 months
            </h3>
          </div>
          <div className="border-b flex justify-between">
            <table className="w-full text-left">
              <thead className="border-b">
                <tr>
                  <th className="p-4 font-normal">Loan Provider</th>
                  <th className="p-4 font-normal">Date</th>
                </tr>
              </thead>
              <tbody>
                {displayedCreditEnquiry ? (
                  displayedCreditEnquiry.map((item: any, index: number) => (
                    <tr key={index}>
                      <td className="p-4">{item.loanProvider}</td>
                      <td>{item.maturityDate}</td>
                    </tr>
                  ))
                ) : (
                  <td colSpan={2} className="p-4">
                    Invalid date
                  </td>
                )}
              </tbody>
            </table>
          </div>
          <div className="flex justify-between items-center p-2">
            <button
              type="button"
              className={`p-2 text-white 
              ${
                pagination.creditEnquiry === 0
                  ? "bg-gray-300 cursor-default"
                  : "bg-secondary cursor-pointer hover:bg-secondary-dark"
              }`}
              onClick={() => prevPage("creditEnquiry")}
              disabled={pagination.creditEnquiry === 0}
            >
              ← Prev
            </button>
            <button
              type="button"
              className={`p-2 text-white 
              ${
                (pagination.creditEnquiry + 1) * oneLoanPerPage >=
                loanHistoryItems.length
                  ? "bg-gray-300 cursor-default"
                  : "bg-secondary cursor-pointer hover:bg-secondary-dark"
              }`}
              onClick={() =>
                nextPage("creditEnquiry", loanHistoryItems, oneLoanPerPage)
              }
              disabled={
                (pagination.creditEnquiry + 1) * oneLoanPerPage >=
                loanHistoryItems.length
              }
            >
              Next →
            </button>
          </div>
        </section>
      </section>

      <section className="my-5">
        <h3 className="font-semibold text-lg mb-3">Loan Performance</h3>
        <table className="border w-full">
          <thead>
            <tr className="text-left border-b">
              <th className="py-4 px-2 text-sm font-medium text-nowrap">
                Loan Provider
              </th>
              <th className="py-4 px-2 text-sm font-medium text-nowrap">
                Loan count
              </th>
              <th className="py-4 px-2 text-sm font-medium text-nowrap">
                Loan amount
              </th>
              <th className="py-4 px-2 text-sm font-medium text-nowrap">
                Outstanding balance
              </th>
              <th className="py-4 px-2 text-sm font-medium text-nowrap">
                Overdue amount
              </th>
              <th className="py-4 px-2 text-sm font-medium text-nowrap">
                Status
              </th>
              <th className="py-4 px-2 text-sm font-medium text-nowrap">
                Performance status
              </th>
            </tr>
          </thead>
          <tbody>
            {displayedLoanPerformance ? (
              displayedLoanPerformance.map((item: any, index: number) => (
                <tr key={index} className="border-b">
                  <td className="py-4 px-2 text-sm">{item.loanProvider}</td>
                  <td className="py-4 px-2 text-sm">{item.loanCount}</td>
                  <td className="py-4 px-2 text-sm text-nowrap">
                    {item.loanAmount}
                  </td>
                  <td className="py-4 px-2 text-sm text-nowrap">
                    {item.outstandingBalance}
                  </td>
                  <td className="py-4 px-2 text-sm">{item.overdueAmount}</td>
                  <td className="py-4 px-1 text-sm">
                    <span
                      className={
                        item.status === "Closed"
                          ? "bg-secondary text-white rounded-md p-1"
                          : "bg-gray-200 rounded-md p-1"
                      }
                    >
                      {item.status}
                    </span>
                  </td>
                  <td className="py-4 px-1 h-[4.5rem] text-sm flex items-center justify-between">
                    <span
                      className={
                        item.performance === "Performing"
                          ? "bg-secondary text-white rounded-md p-1"
                          : "bg-gray-200 rounded-md p-1"
                      }
                    >
                      {item.performanceStatus}
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7} className="text-center py-4">
                  {" "}
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="flex justify-between items-center p-2 border-x border-b">
          <button
            type="button"
            className={`p-2 text-white 
              ${
                pagination.performancePage === 0
                  ? "bg-gray-300 cursor-default"
                  : "bg-secondary cursor-pointer hover:bg-secondary-dark"
              }`}
            onClick={() => prevPage("performancePage")}
            disabled={pagination.performancePage === 0}
          >
            ← Prev
          </button>
          <button
            type="button"
            className={`p-2 text-white 
              ${
                (pagination.performancePage + 1) * loansPerPage >=
                loanPerformanceItems.length
                  ? "bg-gray-300 cursor-default"
                  : "bg-secondary cursor-pointer hover:bg-secondary-dark"
              }`}
            onClick={() =>
              nextPage("performancePage", loanPerformanceItems, loansPerPage)
            }
            disabled={
              (pagination.performancePage + 1) * loansPerPage >=
              loanPerformanceItems.length
            }
          >
            Next →
          </button>
        </div>
      </section>

      <section className="my-5">
        <h3 className="font-semibold text-lg mb-3">Loan History</h3>
        <table className="border w-full">
          <thead>
            <tr className="text-left border-b">
              <th className="py-4 px-2 text-sm font-medium">Loan Provider</th>
              <th className="py-4 px-2 text-sm font-medium">Loan count</th>
              <th className="py-4 px-2 text-sm font-medium">Loan amount</th>
              <th className="py-4 px-2 text-sm font-medium">
                Outstanding balance
              </th>
              <th className="py-4 px-2 text-sm font-medium">Overdue amount </th>
              <th className="py-4 px-2 text-sm font-medium">Status </th>
              <th className="py-4 px-2 text-sm font-medium">Date</th>
            </tr>
          </thead>
          <tbody>
            {displayedLoanHistory.map((item: any, index: number) => (
              <tr key={index} className="border-b">
                <td className="py-4 px-2 text-sm">{item.loanProvider}</td>
                <td className="py-4 px-2 text-sm">{item.count}</td>
                <td className="py-4 px-2 text-sm text-nowrap">
                  {item.loanAmount}
                </td>
                <td className="py-4 px-2 text-sm text-nowrap">
                  {item.outstandingBalance}
                </td>
                <td className="py-4 px-2 text-sm">{item.overdueAmount}</td>
                <td className="py-4 px-1 text-sm">
                  <span
                    className={
                      item.performanceStatus === "Closed"
                        ? "bg-secondary text-white rounded-md p-1"
                        : "bg-gray-200 rounded-md p-1"
                    }
                  >
                    {item.performanceStatus}
                  </span>
                </td>
                <td className="py-4 px-2 text-sm h-[4.5rem] flex items-center justify-between">
                  {item.maturityDate}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-between items-center p-2 border-x border-b">
          <button
            type="button"
            className={`p-2 text-white 
              ${
                pagination.historyPage === 0
                  ? "bg-gray-300 cursor-default"
                  : "bg-secondary cursor-pointer hover:bg-secondary-dark"
              }`}
            onClick={() => prevPage("historyPage")}
            disabled={pagination.historyPage === 0}
          >
            ← Prev
          </button>
          <button
            type="button"
            className={`p-2 text-white 
              ${
                (pagination.historyPage + 1) * loansPerPage >=
                loanHistoryItems.length
                  ? "bg-gray-300 cursor-default"
                  : "bg-secondary cursor-pointer hover:bg-secondary-dark"
              }`}
            onClick={() =>
              nextPage("historyPage", loanHistoryItems, loansPerPage)
            }
            disabled={
              (pagination.historyPage + 1) * loansPerPage >=
              loanHistoryItems.length
            }
          >
            Next →
          </button>
        </div>
      </section>
    </div>
  );
};

export default CreditDetails;
