import { useEffect, useState } from "react";
import LoadingSpinner from "../../components/elements/LoadingSpinner";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { RestaurantOrders } from "../../components/modals/RestaurantOrders";

import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";

export const RestaurantInfo = () => {
  const { token } = useAppSelector((store: RootState) => store.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [restaurantDetails, setRestaurantDetails] = useState<any>([]);
  const [showRestaurantOrders, setShowRestaurantOrders] = useState(false);
  const [orderDetails, setOrderDetails] = useState([]);
  const [totalApproved, setTotalApproved] = useState(0);
  const [totalPending, setTotalPending] = useState(0);
  const [totalOngoing, setTotalOngoing] = useState(0);
  const [filter, setFilter] = useState("");
  const location = useLocation();

  const handleNameClick = (orderDetails: any) => {
    setShowRestaurantOrders((prev) => !prev);
    setOrderDetails(orderDetails);
  };

  useEffect(() => {
    const getRestaurantDetails = async () => {
      setIsLoading(true);
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/getAvailableRequestByRestaurant/${location.state.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setRestaurantDetails(res.data);
        setIsLoading((prev) => !prev);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };

    getRestaurantDetails();
  }, [location.state.id, token]);

  useEffect(() => {
    let approvedCount = 0;
    let pendingCount = 0;
    let ongoingCount = 0;
    
    if (restaurantDetails.requests && restaurantDetails.requests.length > 0) {
      restaurantDetails.requests.forEach((item: any) => {
        if (item.status === "approved") {
          approvedCount++;
        } else if (item.status === "pending") {
          pendingCount++;
        } else if (item.status === "ongoing") {
          ongoingCount++;
        }
      });  
    }

    setTotalApproved(approvedCount);
    setTotalPending(pendingCount);
    setTotalOngoing(ongoingCount);
  }, [restaurantDetails]);

  return (
    <main>
      <h2 className="font-bold text-lg uppercase">{location.state.name}</h2>
      <section className="flex mt-5 justify-evenly flex-wrap gap-3">
        <div
          className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-60 ${
            isLoading && "animate-pulse "
          }`}
        >
          <h2 className="text-sm font-medium pb-4 text-gray-700 caption-bottom">
            Total Approved
          </h2>
          <p className="font-semibold text-3xl">{totalApproved}</p>
        </div>
        <div
          className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-60 ${
            isLoading && "animate-pulse "
          }`}
        >
          <h2 className="text-sm font-medium pb-4 text-gray-700 caption-bottom">
            Total Ongoing
          </h2>
          <p className="font-semibold text-3xl">{totalOngoing}</p>
        </div>
        <div
          className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-60 ${
            isLoading && "animate-pulse "
          }`}
        >
          <h2 className="text-sm font-medium pb-4 text-gray-700 caption-bottom">
            Total Pending
          </h2>
          <p className="font-semibold text-3xl">{totalPending}</p>
        </div>
      </section>
      <section className="my-5 overflow-x-scroll">
        <section className="md:w-full w-[700px]">
          <div className="flex justify-between items-center w-full bg-white rounded-lg p-3 mb-2 gap-2">
            <h1 className="font-semibold ">All Orders</h1>
            <label className="md:text-sm text-xs" htmlFor="filter">
              Sort:
              <select
                name="filter"
                id="filter"
                className="border p-2 ml-4"
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value="">All</option>
                <option value="pending">Pending</option>
                <option value="ongoing">Ongoing</option>
                <option value="approved">Approved</option>
                <option value="cancelled">Cancelled</option>
              </select>
            </label>
          </div>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <table className="w-full text-left">
              <thead>
                <tr className="bg-gray-50 font-bold h-12 md:text-base text-sm">
                  <th>SN</th>
                  <th>Client Name</th>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {restaurantDetails.requests && restaurantDetails.requests.length > 0 ? (
                  restaurantDetails.requests
                    .filter((restaurants: any) => {
                      if (filter === "pending") {
                        return restaurants.status === "pending";
                      } else if (filter === "ongoing") {
                        return restaurants.status === "ongoing";
                      } else if (filter === "approved") {
                        return restaurants.status === "approved";
                      } else if (filter === "cancelled") {
                        return restaurants.status === "cancelled";
                      } else {
                        return restaurants;
                      }
                    })
                    .map((item: any, index: any) => (
                      <tr key={index} className="border-b-2">
                        <td className="md:text-base text-sm">{index + 1}</td>
                        <td
                          className="py-3 cursor-pointer hover:underline hover:text-secondary capitalize"
                          onClick={() => handleNameClick(item)}
                        >
                          {item.customer.name}
                        </td>
                        <td className="py-3 md:text-base text-sm">
                          {new Date(item.createdAt).toLocaleDateString()}
                        </td>
                        <td
                          className={`py-3 md:text-base text-sm ${
                            item.status === "approved" && "text-secondary"
                          } ${item.status === "pending" && "text-yellow-500"} ${
                            item.status === "ongoing" && "text-yellow-300"
                          } ${item.status === "cancelled" && "text-red-500"}`}
                        >
                          {item.status}
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td
                      colSpan={4}
                      className="p-5 bg-white text-center text-secondary"
                    >
                      No Restaurant Orders!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </section>
      </section>
      {showRestaurantOrders && (
        <RestaurantOrders
          setShowRestaurantOrders={setShowRestaurantOrders}
          orderDetails={orderDetails}
        />
      )}
    </main>
  );
};
