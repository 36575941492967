import axios from "axios";
import { useEffect, useState } from "react";

import { useAppSelector } from "../../redux/hooks";
import LoadingSpinner from "../../components/elements/LoadingSpinner";
import ViewAdminAssignment from "../../components/modals/ViewAdminAssignment";

const Staff = () => {
  const { token } = useAppSelector((store) => store.auth);
  const [allAdmin, setAllAdmin] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [viewAssignment, setViewAssignment] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState({
    data: [],
    name: "",
  });

  useEffect(() => {
    const getAllAdmin = async () => {
      setIsLoading(true);
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/get-admins-except-superadmin`, {
          headers: {
            Authorization: token,
          },
        });
        setIsLoading(false);
        setAllAdmin(res.data);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    getAllAdmin();
  }, [token]);

  const handleClick = (data: any, name: string) => {
    setSelectedAdmin({ data, name });
    setViewAssignment((prev) => !prev);
  };

  return (
    <div className="bg-white rounded-md shadow-md pb-6">
      <h1 className="text-lg font-semibold p-3">All Admins</h1>
      <section className="overflow-x-auto">
        <table className="w-[1200px]" style={{ minWidth: "700px" }}>
          <thead className="bg-gray-50 font-bold p-4 text-left text-nowrap">
            <tr>
              <th className="p-2">SN</th>
              <th className="p-2">Name</th>
              <th className="p-2">Email</th>
              <th className="p-2">Staff Id</th>
              <th className="p-2">Admin Level</th>
              <th className="p-2">Created at</th>
              <th className="p-2">Assignments</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={7} className="p-4 mx-auto">
                  <LoadingSpinner />
                </td>
              </tr>
            ) : allAdmin.length > 0 ? (
              allAdmin.map((admin: any, index) => (
                <tr key={index}>
                  <td className="text-secondary p-2">{index + 1}</td>
                  <td className="p-2">{`${admin.lastName} ${admin.firstName}`}</td>
                  <td className="p-2">{admin.email}</td>
                  <td className="p-2">{admin.staffId}</td>
                  <td className="p-2 capitalize">{admin.adminLevel}</td>
                  <td className="p-2">
                    {new Date(admin.createdAt).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </td>
                  <td className="p-2 flex flex-col items-center">
                    <button
                      type="button"
                      onClick={() =>
                        handleClick(admin.adminRole, admin.lastName)
                      }
                      className="p-2 bg-secondary text-sm text-white rounded-md"
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7} className="p-4 text-secondary text-center">
                  No Admin found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
      {viewAssignment && (
        <ViewAdminAssignment
          setViewAssignment={setViewAssignment}
          assignmentData={selectedAdmin}
        />
      )}
    </div>
  );
};

export default Staff;
