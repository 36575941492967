import axios from "axios";
import { useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store";
import { Preloader } from "../elements/Preloader";

export const AllBanksModal = ({
  userId,
  setAllBanksModal,
  allBanksDetails,
  setAllBanksDetails,
  flexibleCustomersFunc,
  getAllUsers,
  documentCheckPage,
  setDocumentCheckPage,
}: any) => {
  const [isLoading, setIsLoading] = useState(
    new Array(allBanksDetails.length).fill(false)
  );
  const [acctMandate, setAcctMandate] = useState(
    allBanksDetails.map((bank: any) => ({
      id: bank._id,
      account_number: bank.account_number,
      isMandate: bank.isMandate || false,
    }))
  );
  const { token } = useAppSelector((store: RootState) => store.auth);

  const handleCloseModal = () => {
    if (flexibleCustomersFunc) {
      flexibleCustomersFunc();
    }
    if (getAllUsers) {
      getAllUsers();
    }
    if (setDocumentCheckPage) setDocumentCheckPage(false);
    setAllBanksModal(false);
    setAllBanksDetails([]);
  };

  const checkMandateLimit = () => {
    const mandateCount = acctMandate.filter(
      (bank: any) => bank.isMandate
    ).length;
    return mandateCount === 2;
  };

  const handleMandate = async (index: number, id: string) => {
    const payload = { userId: userId };
    setIsLoading((prev) => prev.map((item, i) => (i === index ? true : item)));
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/toggleCustomerSavedBankIsMandate/${id}`,
        payload,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      if (res.status === 200) {
        setAcctMandate((prev: any) =>
          prev.map((mandate: any) =>
            mandate.id === id
              ? { ...mandate, isMandate: !mandate.isMandate }
              : mandate
          )
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading((prev) =>
        prev.map((item, i) => (i === index ? false : item))
      );
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 overflow-y-auto">
      <section className="overflow-y-auto bg-white">
        <div className="bg-white p-4 rounded-md h-screen">
          <div className="flex justify-between items-center  text-dark p-2 rounded-t-md mb-4">
            <h2 className="font-semibold">ALL BANKS DETAILS</h2>
            <button onClick={handleCloseModal} className="text-dark">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <section>
            <table className="w-full text-left mb-5">
              <thead className="border bg-gray-50">
                <tr>
                  <th className="p-2 text-xs">Account holder Name</th>
                  <th className="p-2 text-xs">Account number</th>
                  <th className="p-2 text-xs">Bank Name</th>
                  <th className="p-2 text-xs">Is Mandate</th>
                  <th className="p-2 text-xs">Set Mandate</th>
                </tr>
              </thead>
              <tbody>
                {allBanksDetails.length > 0 ? (
                  allBanksDetails.map((item: any, index: number) => {
                    const mandate = acctMandate.find(
                      (m: any) => m.id === item._id
                    );
                    return (
                      <tr key={index}>
                        <td className="p-2 border text-xs">
                          {item.account_name}
                        </td>
                        <td className="p-2 border text-xs">
                          {item.account_number}
                        </td>
                        <td className="p-2 border text-xs">
                          {item.institution.name}
                        </td>
                        <td className="p-2 border text-xs">
                          {mandate.isMandate ? "Active" : "Inactive"}
                        </td>
                        {isLoading[index] ? (
                          <td className="p-2 border">
                            <Preloader />
                          </td>
                        ) : documentCheckPage ? null : (
                          <td className="p-2 border text-xs">
                            <input
                              type="checkbox"
                              name="setMandate"
                              id="setMandate"
                              className="cursor-pointer"
                              disabled={
                                checkMandateLimit() && !mandate.isMandate
                              }
                              checked={mandate?.isMandate}
                              onChange={() => handleMandate(index, item._id)}
                            />
                            <span className="ml-2">₦20000</span>
                          </td>
                        )}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={5} className="text-center p-2">
                      No linked banks
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </section>
        </div>
      </section>
    </div>
  );
};
