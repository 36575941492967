import { useEffect, useState } from "react";
import VAcctModal from "../components/modals/VAcctModal";
import axios from "axios";

export const VirtualAccount = () => {
  const [allAccounts, setAllAccounts] = useState<[]>([]);
  const [vAcct, setVAcct] = useState({});
  const [showVAcct, setShowVAcct] = useState(false);

  useEffect(() => {
    const fetchAcct = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/getAllVirtualAccountDetails`
        );
        setAllAccounts(res.data.customers)
      } catch (error) {
        console.error(error);
      }
    };
    fetchAcct()
  }, []);

  const handleViewAcct = (acctData: object) => {
    setVAcct(acctData);
    setShowVAcct((prev) => !prev);
  };

  return (
    <main className="w-full">
      <div className="bg-white p-3 rounded-md mb-3">
        <h1 className="font-bold text-lg">Virtual Account</h1>
      </div>
      <section>
        <table className="w-full text-left">
          <thead className="bg-gray-50 h-12 rounded-md">
            <tr>
              <th className="px-2">SN</th>
              <th className="px-2">Name</th>
              <th className="px-2">Account</th>
            </tr>
          </thead>
          <tbody>
            {allAccounts.map((item: any, index) => (
              <tr key={index} className="border-b">
                <td className="p-2">{index + 1}</td>
                <td className="p-2">{item.lastName + " " + item.firstName}</td>
                <td className="p-2">
                  <button
                    type="button"
                    onClick={() => handleViewAcct(item.virtualAccount)}
                    className="bg-secondary text-sm text-white p-2 rounded-md hover:bg-green-700"
                  >
                    View Account
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
      {showVAcct && <VAcctModal setShowVAcct={setShowVAcct} acctData={vAcct} />}
    </main>
  );
};
