import axios from "axios";
import { useEffect, useState } from "react";
import LoadingSpinner from "../../components/elements/LoadingSpinner";


const OrderHistory = ({ userId, token }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userOrders, setUserOrders] = useState<any>([]);
  const [showItems, setShowItems] = useState(false);
  const [orderDetails, setOrderDetails] = useState<any>([]);

  useEffect(() => {
    getOrders();
  }, []);

  const handleClick = (item: any) => {
    setOrderDetails(item);
    setShowItems((prev) => !prev);
  };

  const getOrders = async () => {
    setIsLoading(true);

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/get-orders-by-id/${userId}`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setIsLoading(false);
      setUserOrders(res.data);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  return (
    <section className="bg-white p-5 rounded-md">
      <h3 className="font-bold mb-2 underline">Order History</h3>
      <div>
        <table className="w-full">
          <thead className="text-left rounded bg-gray-100">
            <tr>
              <th className="p-3">SN</th>
              <th>Date</th>
              <th>Order Number</th>
              <th>Status</th>
              <th>Total price</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={5} className="p-5">
                  <LoadingSpinner />
                </td>
              </tr>
            ) : userOrders.length > 0 ? (
              userOrders.map((order: any, index: any) => (
                <tr
                  key={index}
                  className="hover:bg-gray-50 cursor-pointer"
                  onClick={() => handleClick(order)}
                >
                  <td className="p-3 border-b">{index + 1}</td>
                  <td className="border-b">
                    {new Date(order.orderDate).toLocaleDateString()}
                  </td>
                  <td className="border-b">{order.orderNumber}</td>
                  <td className="border-b">{order.status}</td>
                  <td className="border-b">₦{Math.round(order.allItemsTotalPrice)}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className="text-center text-secondary p-5">
                  No orders found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {showItems && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-4 rounded-md" style={{ width: "500px" }}>
            <div className="flex justify-between items-center  text-dark p-2 rounded-t-md mb-4">
              <h2 className="font-semibold">DELIVERY DETAILS</h2>
              <button onClick={() => setShowItems(false)} className="text-dark">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <section>
              <h3 className="font-bold text-lg">{orderDetails.date}</h3>
              <table className="w-full text-left mb-5">
                <thead className="border bg-gray-50">
                  <tr>
                    <th className="p-2 text-sm">Items</th>
                    <th className="p-2 text-sm">Measurement</th>
                    <th className="p-2 text-sm">Quantity</th>
                    <th className="p-2 text-sm">Price</th>
                  </tr>
                </thead>
                <tbody>
                  {orderDetails.orderItems.map((item: any, index: any) => (
                    <tr key={index}>
                      <td className="p-2 border text-sm">{item.name}</td>
                      <td className="p-2 border text-sm">{item.measurement}</td>
                      <td className="p-2 border text-sm">{item.quantity}</td>
                      <td className="p-2 border text-sm">{item.totalPrice}</td>
                    </tr>
                  ))}
                  <tr>
                    <td
                      colSpan={3}
                      className="p-2 border text-sm font-semibold"
                    >
                      Total price
                    </td>
                    <td className="border font-semibold">₦{Math.round(orderDetails.allItemsTotalPrice)}</td>
                  </tr>
                </tbody>
              </table>
              <div>
                <p className="font-semibold">
                  Delivery Location: {orderDetails.deliveryDetails}
                </p>
              </div>
            </section>
          </div>
        </div>
      )}
    </section>
  );
};

export default OrderHistory;
