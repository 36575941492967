import { useEffect, useState } from "react";
import DietitianRequestModal from "../../components/modals/DietitianRequestModal";

const DietitianRequests = () => {
  const [showRequest, setShowRequest] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState<object>({});
  const [treatedRequest, setTreatedRequest] = useState(0);
  const [processingRequest, setProcessingRequest] = useState(0);
  const [pendingRequest, setPendingRequest] = useState(0);
  const [status] = useState("");

  const data = [
    {
      clientName: "Amarachi S",
      image: '/assets/picture.jpg',
      request: {
        name: "Amarachi Sarah",
        phoneNumber: "0800233564",
        bodyWeight: "34kg",
        bmi: "400",
        gender: "female",
        allergies: ["peanuts", "eggs"],
        currentDiet: "keto-diet",
        medicalConditions: ["asthma"],
        physicalActivities: ["walk"],
        otherInformation: "akjdkdfjksdhfwenm, aksjdh kasd kasjdfkaj",
      },
      status: "treated",
      dietician: "Marcus G",
      date: "3/10/2024",
    },
    {
      clientName: "Amarachi S",
      image: '/assets/picture.jpg',
      request: {
        name: "Amarachi Sarah",
        phoneNumber: "0800233564",
        bodyWeight: "34kg",
        bmi: "400",
        gender: "female",
        allergies: ["peanuts", "eggs"],
        currentDiet: "keto-diet",
        medicalConditions: ["asthma"],
        physicalActivities: ["walk"],
        otherInformation: "akjdkdfjksdhfwenm, aksjdh kasd kasjdfkaj",
      },
      status: "processing",
      dietician: "none",
      date: "3/10/2024",
    },
    {
      clientName: "Amarachi S",
      image: '/assets/picture.jpg',
      request: {
        name: "Amarachi Sarah",
        phoneNumber: "0800233564",
        bodyWeight: "34kg",
        bmi: "400",
        gender: "female",
        allergies: ["peanuts", "eggs"],
        currentDiet: "keto-diet",
        medicalConditions: ["asthma"],
        physicalActivities: ["walk"],
        otherInformation: "akjdkdfjksdhfwenm, aksjdh kasd kasjdfkaj",
      },
      status: "pending",
      dietician: "Marcus G",
      date: "3/10/2024",
    },
    {
      clientName: "Amarachi S",
      image: '/assets/picture.jpg',
      request: {
        name: "Amarachi Sarah",
        phoneNumber: "0800233564",
        bodyWeight: "34kg",
        bmi: "400",
        gender: "female",
        allergies: ["peanuts", "eggs"],
        currentDiet: "keto-diet",
        medicalConditions: ["asthma"],
        physicalActivities: ["walk"],
        otherInformation: "akjdkdfjksdhfwenm, aksjdh kasd kasjdfkaj",
      },
      status: "treated",
      dietician: "Marcus G",
      date: "3/10/2024",
    },
    {
      clientName: "Amarachi S",
      image: '/assets/picture.jpg',
      request: {
        name: "Amarachi Sarah",
        phoneNumber: "0800233564",
        bodyWeight: "34kg",
        bmi: "400",
        gender: "female",
        allergies: ["peanuts", "eggs"],
        currentDiet: "keto-diet",
        medicalConditions: ["asthma"],
        physicalActivities: ["walk"],
        otherInformation: "akjdkdfjksdhfwenm, aksjdh kasd kasjdfkaj",
      },
      status: "treated",
      dietician: "Marcus G",
      date: "3/10/2024",
    },
    {
      clientName: "Amarachi S",
      image: '/assets/picture.jpg',
      request: {
        name: "Amarachi Sarah",
        phoneNumber: "0800233564",
        bodyWeight: "34kg",
        bmi: "400",
        gender: "female",
        allergies: ["peanuts", "eggs"],
        currentDiet: "keto-diet",
        medicalConditions: ["asthma"],
        physicalActivities: ["walk"],
        otherInformation: "akjdkdfjksdhfwenm, aksjdh kasd kasjdfkaj",
      },
      status: "treated",
      dietician: "Marcus G",
      date: "3/10/2024",
    },
    {
      clientName: "Amarachi S",
      image: '/assets/picture.jpg',
      request: {
        name: "Amarachi Sarah",
        phoneNumber: "0800233564",
        bodyWeight: "34kg",
        bmi: "400",
        gender: "female",
        allergies: ["peanuts", "eggs"],
        currentDiet: "keto-diet",
        medicalConditions: ["asthma"],
        physicalActivities: ["walk"],
        otherInformation: "akjdkdfjksdhfwenm, aksjdh kasd kasjdfkaj",
      },
      status: "treated",
      dietician: "Marcus G",
      date: "3/10/2024",
    },
    {
      clientName: "Amarachi S",
      image: '/assets/picture.jpg',
      request: {
        name: "Amarachi Sarah",
        phoneNumber: "0800233564",
        bodyWeight: "34kg",
        bmi: "400",
        gender: "female",
        allergies: ["peanuts", "eggs"],
        currentDiet: "keto-diet",
        medicalConditions: ["asthma"],
        physicalActivities: ["walk"],
        otherInformation: "akjdkdfjksdhfwenm, aksjdh kasd kasjdfkaj",
      },
      status: "treated",
      dietician: "Marcus G",
      date: "3/10/2024",
    },
    {
      clientName: "Amarachi S",
      image: '/assets/picture.jpg',
      request: {
        name: "Amarachi Sarah",
        phoneNumber: "0800233564",
        bodyWeight: "34kg",
        bmi: "400",
        gender: "female",
        allergies: ["peanuts", "eggs"],
        currentDiet: "keto-diet",
        medicalConditions: ["asthma"],
        physicalActivities: ["walk"],
        otherInformation: "akjdkdfjksdhfwenm, aksjdh kasd kasjdfkaj",
      },
      status: "treated",
      dietician: "Marcus G",
      date: "3/10/2024",
    },
  ];

  useEffect(() => {
    let treated = 0;
    let processing = 0;
    let pending = 0;

    data.forEach((item) => {
      if (item.status === "treated") {
        treated++;
      } else if (item.status === "processing") {
        processing++;
      } else {
        pending++;
      }
    });
    setTreatedRequest(treated);
    setPendingRequest(pending);
    setProcessingRequest(processing);
  }, []);
  const handleClick = (request: object) => {
    setSelectedRequest(request);
    setShowRequest((prev) => !prev);
  };

  return (
    <div>
      <h1 className="font-bold text-xl">Dietitian Requests</h1>
      <section className="flex mt-5 justify-evenly flex-wrap gap-3">
        <div
          className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-60 ${
            status === "pending" && "animate-pulse "
          }`}
        >
          <h2 className="text-sm font-medium pb-4 text-gray-700 caption-bottom">
            Total Requests
          </h2>
          <p className="font-semibold text-3xl">{data.length}</p>
        </div>
        <div
          className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-60 ${
            status === "pending" && "animate-pulse "
          }`}
        >
          <h2 className="text-sm font-medium pb-4 text-gray-700 caption-bottom">
            Treated Requests
          </h2>
          <p className="font-semibold text-3xl">{treatedRequest}</p>
        </div>
        <div
          className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-60 ${
            status === "pending" && "animate-pulse "
          }`}
        >
          <h2 className="text-sm font-medium pb-4 text-gray-700 caption-bottom">
            Processing Requests
          </h2>
          <p className="font-semibold text-3xl">{processingRequest}</p>
        </div>
        <div
          className={`bg-white p-5 rounded-md border border-green-300 shadow-md w-60 ${
            status === "pending" && "animate-pulse "
          }`}
        >
          <h2 className="text-sm font-medium pb-4 text-gray-700 caption-bottom">
            Pending Requests
          </h2>
          <p className="font-semibold text-3xl">{pendingRequest}</p>
        </div>
      </section>
      <section className="my-5 p-3 bg-white rounded-md">
        <section className="flex flex-wrap md:gap-4 my-3">
          {data && data.length > 0 ? (
            data.map((request, index) => (
              <div
                key={index}
                className="flex flex-col text-center cursor-pointer items-center w-fit border shadow-md p-3 rounded-sm"
                onClick={() => handleClick(request.request)}
              >
                <div className="w-20 h-20 rounded-full border">
                  <img
                    src={request.image}
                    className="w-full h-full"
                    alt={request.clientName}
                  />
                </div>
                <div className="">
                  <h3 className="font-semibold">{request.clientName}</h3>
                  <p>
                    {request.dietician !== "none"
                      ? `DR. ${request.dietician}`
                      : request.dietician}
                  </p>
                  <p className="text-sm">{request.status}</p>
                </div>
              </div>
            ))
          ) : (
            <p className="font-semibold text-center">No Requests</p>
          )}
        </section>
      </section>

      {showRequest && (
        <DietitianRequestModal
          setShowRequest={setShowRequest}
          selectedRequest={selectedRequest}
        />
      )}
    </div>
  );
};

export default DietitianRequests;
