const DietitianMessage = ({ setShowMessageBox, clientName }: any) => {
  const sendMessage = (e: any) => {
    e.preventDefault();
  };
  return (
    <div className="fixed inset-0 z-20 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div
        className="bg-white p-4 rounded-md overflow-auto"
        style={{ width: "500px", height: "400px" }}
      >
        <div className="flex justify-between items-center text-dark p-2 rounded-t-md mb-4">
          <h2 className="text-lg font-semibold">
            Send message to {clientName}
          </h2>
          <button
            onClick={() => setShowMessageBox(false)}
            className="text-dark"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <section>
          <textarea
            name="message"
            id="message"
            className="border rounded-md w-full text-sm p-2"
            placeholder={`Hello, ${clientName}`}
            cols={30}
            rows={10}
          ></textarea>
          <input
            type="button"
            value="Send"
            className="p-2 bg-secondary text-sm text-white"
            onClick={(e) => sendMessage(e)}
          />
        </section>
      </div>
    </div>
  );
};

export default DietitianMessage;
